import React from 'react';
import {useI18next} from 'gatsby-plugin-react-i18next';
import {InternalPageLink, InternalPageSlug} from '../links/Pages';

type PaymentMethod =
  | 'credit-cards'
  | 'google-pay'
  | 'apple-pay'
  | 'paypal'
  | 'click-to-pay'
  | 'bizum'
  | 'bancontact'
  | 'multibanco'
  | 'mb-way'
  | 'sepa-direct-debit'
  | 'sepa-request-to-pay';

interface Props {
  exclude: PaymentMethod[];
  endTag?: string;
}

export const PaymentMethodsLinkList: React.FC<Props> = ({exclude = [], endTag}) => {
  const {t} = useI18next();
  console.log({exclude});
  const paymentMethods: {label: string; slug: InternalPageSlug}[] = [
    {slug: 'payment-methods/credit-cards', label: t('cards', {ns: 'common'})},
    {slug: 'payment-methods/google-pay', label: 'Google Pay'},
    {slug: 'payment-methods/apple-pay', label: 'Apple Pay'},
    {slug: 'payment-methods/paypal', label: 'PayPal'},
    {slug: 'payment-methods/click-to-pay', label: 'Click to Pay'},
    {slug: 'payment-methods/bizum', label: 'Bizum'},
    {slug: 'payment-methods/bancontact', label: 'Bancontact'},
    {slug: 'payment-methods/multibanco', label: 'Multibanco'},
    {slug: 'payment-methods/mb-way', label: 'MB WAY'},
    {slug: 'payment-methods/sepa-direct-debit', label: 'SEPA Direct Debit'},
    {slug: 'payment-methods/sepa-request-to-pay', label: 'SEPA Request-to-Pay'}
  ];
  return (
    <>
      {paymentMethods
        .filter(
          (method) => !exclude.some((item) => ('payment-methods/' + item).includes(method.slug))
        )
        .map((method, i, arr) => {
          return (
            <>
              <InternalPageLink key={method.slug} slug={method.slug}>
                {method.label}
              </InternalPageLink>
              {i < arr.length - 1 && <>, </>}
              {i === arr.length - 2 && <>{endTag} </>}
            </>
          );
        })}
    </>
  );
};
