// i18next-extract-mark-ns-start giropay

//Landing redirected to /payment-methods to hide from BdE license - Discussed with David and Alex on 2024-08-08

//Payment methods
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Images
import giropay_monei from 'images/giropay_monei.svg';
import person from 'images/person.svg';
import mobile from 'images/mobile.svg';
import click1 from 'images/click1.svg';
import password from 'images/password.svg';

// Clients
import React from 'react';
import styled from 'styled-components';
import {AnchorLink} from 'components/AnchorLink';
import {PaymentMethodsLinkList} from 'components/landings/PaymetMethodsLinkList';
import index1 from 'images/payment_gateway.svg';
import {BlogLink} from 'components/links/Blog';
import {IntegrationGuide} from 'components/payment-methods/IntegrationGuide';
import {InternalPageLink} from 'components/links/Pages';
import {ScreenSizes} from '../../../types/responsive';
import {FaqsSection} from 'components/FaqsSection';
import {Card} from 'components/Card';
import CardsContainer from 'components/CardsContainer';

const Bold = styled.span`
  display: inline;
  font-weight: 600;
`;

const CardWrapper = styled.div`
  max-width: 230px;
`;

const CardImage = styled.img`
  position: absolute;
`;

const IndexImage = styled.img`
  position: absolute;
  left: 56%;
  top: 20px;
  width: 540px;
  z-index: 9;

  @media (max-width: 1024px) {
    left: 60%;
    width: 45%;
    top: 1%;
  }
`;

const IndexBackground = styled.div`
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: -30%;
    height: 1256px;
    width: 1203px;
    background: linear-gradient(353.74deg, #41efd6 -47.57%, #aab4e9 13.85%);
    transform: matrix(2, 0, 0, -1, 1248, 4) rotate(47deg) skew(7deg, 1.6deg);
    @media (max-width: 1024px) {
      top: -32%;
      transform: matrix(2, 0, 0, -1, 1185, 25) rotate(44.5deg) skew(7deg, 1.6deg);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    transform: rotate(40deg) matrix(1, 0, 0, -1, 459, -315);
    left: 47%;
    top: -20%;
    opacity: 1;
    height: 972px;
    width: 1289px;
    background: linear-gradient(353.74deg, #41efd6 -41.57%, #aab4e9 53.85%);
    @media (max-width: 1024px) {
      top: -35%;
      transform: rotate(34deg) matrix(1, 0, 0, -1, 470, -240);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }
`;

const FirstSection = styled(Section)`
  position: relative;
  padding-top: 0;

  @media (max-width: 768px) {
    margin-top: 40px;
  }
`;

const FirstSectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    margin-bottom: 24px;
  }
`;

const IndexContent = styled.div`
  padding-top: 100px;
  padding-bottom: 0;
  max-width: 550px;
  min-height: 600px;
  @media (max-width: ${ScreenSizes.md}) {
    max-width: 500px;
    min-height: 510px;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    max-width: 100%;
    min-height: 410px;
  }
`;

const OrderList = styled.ol``;

const Giropay: React.FC = () => {
  const {t} = useI18next();

  const faqs = [
    {
      header: t('Which payment methods does MONEI support?'),
      text: t(
        'Consult our payment methods page to see all the local payment methods you can accept when you integrate with MONEI.'
      ),
      content: (
        <Trans>
          Consult our <InternalPageLink slug="features">payment methods page</InternalPageLink> to
          see all the local payment methods you can accept when you integrate with MONEI.
        </Trans>
      )
    },
    {
      header: t('What are all the different types of local payment methods?'),
      text: t(
        'There are many local payment methods used across the world. You don’t have to accept them all. Instead, focus on the ones most relevant to your target market.'
      ),
      content: (
        <Trans>
          There are many <BlogLink slug="local-payment-methods">local payment methods</BlogLink>{' '}
          used across the world. You don’t have to accept them all. Instead, focus on the ones most
          relevant to your target market.
        </Trans>
      )
    },
    {
      header: t('What are interchange fees?'),
      text: t(
        'They are the fees you must pay to card-issuing banks when customers use a credit or debit card to complete a purchase from your business. Learn how they work.'
      ),
      content: (
        <Trans>
          They are the fees you must pay to card-issuing banks when customers use a credit or debit
          card to complete a purchase from your business.{' '}
          <InternalPageLink slug="interchange-fee-calculator">
            Learn how they work.
          </InternalPageLink>
        </Trans>
      )
    },
    {
      header: t('Are there setup fees for MONEI? '),
      text: t(
        'No. And transaction fees are dynamic. As your sales increase, your fees per transaction will decrease. Learn more about MONEI’s pricing.'
      ),
      content: (
        <Trans>
          No. And transaction fees are dynamic. As your sales increase, your fees per transaction
          will decrease. Learn more about{' '}
          <InternalPageLink slug="pricing">MONEI’s pricing. </InternalPageLink>
        </Trans>
      )
    }
  ];

  return (
    <>
      <SEO
        path="giropay"
        title={t('Giropay Payment Method')}
        description={t(
          'Reach customers in Germany by accepting Giropay — one of Germany’s most popular payment method with over one million transactions per month.'
        )}
      />

      <IndexBackground>
        <Content>
          <IndexContent>
            <SectionHeader underline tagName="h1">
              <Trans>Giropay</Trans>
            </SectionHeader>
            <Trans parent="p">
              Giropay has over one million transactions per month making it one of the most popular
              payment methods in Germany. Introduced in 2005, it supports most German banks and lets
              consumers complete payments from their existing online banking environment. To grow
              your business in Germany, accept Giropay payments.
            </Trans>
            <SectionActions>
              <SignUpButton variant="light" style={{marginRight: 15}}>
                <Trans>Open an Account</Trans>
              </SignUpButton>
              <ContactSalesButton />
            </SectionActions>
          </IndexContent>
        </Content>
        <IndexImage
          src={giropay_monei}
          alt="MONEI giropay"
          title="MONEI giropay"
          className="hide-on-mobile"
        />
      </IndexBackground>

      <Content>
        <Section centered textAlign="center" column>
          <SectionHeader>
            <Trans>What is Giropay and its benefits</Trans>
          </SectionHeader>
          <Trans parent="p">
            Giropay is a quick and secure payment method that lets consumers pay using their online
            banking app.
          </Trans>
        </Section>

        <CardsContainer>
          <Card>
            <CardImage src={person} alt="" style={{top: -25}} />
            <Trans parent="h4">Attract more customers</Trans>
            <CardWrapper>
              <Trans parent="p">
                Reach over 45 million online banking consumers (over 50% of Germany’s adult
                population)
              </Trans>
            </CardWrapper>
          </Card>
          <Card>
            <CardImage src={mobile} alt="" style={{top: -35, width: 120}} />
            <Trans parent="h4">Real-time payments</Trans>
            <CardWrapper>
              <Trans parent="p">
                Receive guaranteed payment as soon as the consumer completes the transaction
              </Trans>
            </CardWrapper>
          </Card>
          <Card>
            <CardImage src={click1} alt="" style={{top: -30, width: 90}} />
            <Trans parent="h4">Easy refunds</Trans>
            <CardWrapper>
              <Trans parent="p">Manage full and partial refunds</Trans>
            </CardWrapper>
          </Card>
        </CardsContainer>
        <Section
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '0px',
            paddingBottom: '140px'
          }}>
          <SectionActions>
            <SignUpButton variant="dark">
              <Trans>Discover MONEI</Trans>
            </SignUpButton>
          </SectionActions>
        </Section>
      </Content>

      <Background>
        <Content>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Giropay payment Process</Trans>
              </SectionHeader>
              <Trans parent="p">
                With Giropay, your customers can complete payments without sharing any personal
                information.
              </Trans>
              <OrderList>
                <Trans parent="li">
                  Shopper selects Giropay as their preferred payment method at checkout{' '}
                </Trans>
                <Trans parent="li">Shopper chooses their bank for payment</Trans>
                <Trans parent="li">
                  Shopper logs in to their online banking environment to confirm the transaction
                </Trans>
                <Trans parent="li">You and the shopper receive payment confirmation</Trans>
              </OrderList>
              <Trans>
                Accept Giropay to let customers quickly and safely pay from their online banking
                app. And to stand out from the competition, configure more{' '}
                <InternalPageLink slug="payment-methods">payment methods</InternalPageLink> like{' '}
                <InternalPageLink slug="payment-methods/credit-cards">cards</InternalPageLink> and{' '}
                <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>{' '}
                including{' '}
              </Trans>
              <PaymentMethodsLinkList exclude={['giropay', 'credit-cards']} endTag={t('and')} />.
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={password}
              alt="Giropay safe payment method"
              title="Giropay safe payment method"
              width={247}
              height={217}
              mobileWidth={200}
            />
            <div>
              <SectionHeader>
                <Trans>Is Giropay a safe payment method?</Trans>
              </SectionHeader>

              <Trans parent="p">
                Giropay is known for its adherence to the strict security and data protection
                standards required for online banking, ensuring complete safety for its users. When
                a translation is completed using Giropay, you instantly receive the payment. It’s
                guaranteed, immediate, and risk-free.
              </Trans>
              <Trans parent="p">
                Customers interact exclusively with their bank's online banking system during
                transactions so sensitive personal data is never shared with merchants, eliminating
                the risk of third-party access or visibility of personal information.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Securely accept Giropay</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <IntegrationGuide title={<Trans>Get the Giropay payment integration</Trans>}>
        <Trans>
          Adding Giropay to your business is straightforward. All you have to do is sign up for
          MONEI Once you’ve been approved,{' '}
          <AnchorLink href="https://support.monei.com/hc/requests/new">contact support</AnchorLink>{' '}
          to configure this payment method in your store.
        </Trans>
      </IntegrationGuide>

      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>Giropay FAQ</Trans>} />
        </Section>
      </Content>
    </>
  );
};

export default Giropay;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "giropay"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
